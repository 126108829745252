import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { buildParams } from '@ha/search-query';

import { useIntl } from 'ha/i18n';
import { ListingCardLocations } from 'ha/types/ListingCard';

import { useRoomPageLink } from 'ha/modules/Nextjs';

import { Card } from '../../Card';
import { useListingAnchorTitle } from '../hooks/useListingAnchorTitle';

import { ListingCardInfo } from './ListingCardInfo';
import { ListingCardPhotoGallery } from './ListingCardPhotoGallery';
import { ListingCardProps } from './types';

const useStyles = makeStyles()(() => ({
  cardLink: {
    textDecoration: 'none',
  },
}));

export const ListingCard: React.FC<ListingCardProps> = ({
  onMouseEnter,
  onMouseLeave,
  room,
  withFavorites,
  isLazy,
  onCardClick,
  queryParams,
  noFollow,
  linkTarget,
  cardLocation = ListingCardLocations.unspecified,
  withGallery = true,
  cardPosition,
}) => {
  const { classes } = useStyles();

  const { urlResolver } = useIntl();

  const redirectPath = room.unitTypePath ? room.unitTypePath : room.listingPath;

  const redirectLink = useRoomPageLink(redirectPath, queryParams);

  const handleMouseEnter = React.useCallback(() => {
    onMouseEnter?.(room.id);
  }, [onMouseEnter, room.id]);

  const handleMouseLeave = React.useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave(room.id);
    }
  }, [onMouseLeave, room.id]);

  const { anchorTitle } = useListingAnchorTitle({ ...room });

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a
      href={
        linkTarget === '_blank'
          ? redirectLink
          : `${urlResolver.formathrefURL(redirectPath)}${buildParams(
              queryParams || {},
            )}`
      }
      target={linkTarget || '_self'}
      data-test-locator="Listing Card"
      rel={noFollow ? 'noopener noreferrer' : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onCardClick}
      itemScope
      itemType="http://schema.org/Offer"
      className={classes.cardLink}
      title={anchorTitle}
    >
      <Card>
        <ListingCardPhotoGallery
          room={room}
          isLazy={isLazy}
          cardLocation={cardLocation}
          withGallery={withGallery}
          withFavorites={withFavorites}
          cardPosition={cardPosition}
        />
        <ListingCardInfo room={room} queryParams={queryParams} />
      </Card>
    </a>
  );
};
